import { useEffect, useMemo } from 'react';
import { Updater } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { PromotionFeatures } from '@playq/octopus-common';

import { useDebounce } from '/hooks/useDebounce';
import { useCancelOnUnmount } from '/hooks/useCancellationOnUnmount';
import { activeSpaceToolkit } from '/store/toolkits/space';
import { IDLClassWithSerialize } from '/common/models';
import { isPromotionLoading, getSandboxPromotion } from '/shared/Promotions/helpers';

import { IEntityPromotion, IPromotionServiceWithBatchQuery } from './types';

export interface WatcherDefinition<ID> {
  service: IPromotionServiceWithBatchQuery<ID>;
  features: PromotionFeatures[];

  deserializeID: (id: string) => ID;
}

export const QUERY_PROMOTIONS_TIMEOUT = 3000;

const emptyArr: [] = [];

export function createPromotionsWatcher<ID extends IDLClassWithSerialize>(def: WatcherDefinition<ID>) {
  const { service, features = [PromotionFeatures.AuthorName, PromotionFeatures.SpaceName], deserializeID } = def;

  return (
    promotions: IEntityPromotion<ID>[],
    updatePromotions: (originPromotions: Updater<IEntityPromotion<ID>[], IEntityPromotion<ID>[]>) => void
  ) => {
    const space = useSelector(activeSpaceToolkit.selectors.activeSpace);

    const didCancel = useCancelOnUnmount();

    const queryingIDs = useMemo(() => {
      const ids = promotions.reduce((acc: ID[], promotion) => {
        if (
          isPromotionLoading(promotion.promotions.live) ||
          isPromotionLoading(getSandboxPromotion(promotion.promotions, space))
        ) {
          return acc.concat(promotion.id);
        }
        return acc;
      }, emptyArr);
      return ids;
    }, [promotions, space]);
    const debouncedQueryingIds = useDebounce(queryingIDs, QUERY_PROMOTIONS_TIMEOUT);

    useEffect(() => {
      if (debouncedQueryingIds.length > 0) {
        service.queryPromotionsBatch(debouncedQueryingIds, features).then((data) =>
          data.bifold(
            (res) => {
              if (didCancel.current === true) {
                return;
              }
              updatePromotions((prevPromotions) => {
                const nextPromotions = [...prevPromotions];
                const promoArr = Object.keys(res.promotions).map((id) => ({
                  id: deserializeID(id),
                  promotions: res.promotions[id],
                }));

                promoArr.forEach((promotion) => {
                  const nextPromotionIndex = nextPromotions.findIndex(
                    (p) => p.id.serialize() === promotion.id.serialize()
                  );
                  if (nextPromotionIndex === -1) {
                    nextPromotions.push(promotion);
                  } else {
                    nextPromotions[nextPromotionIndex] = promotion;
                  }
                });
                return nextPromotions;
              });
            },
            (err) => console.error(err)
          )
        );
      }
    }, [debouncedQueryingIds, didCancel, updatePromotions]);

    const pending = useMemo(
      () =>
        promotions.some(
          (p) => isPromotionLoading(p.promotions.live) || isPromotionLoading(getSandboxPromotion(p.promotions, space))
        ),
      [promotions, space]
    );

    return { promotions, pending } as const;
  };
}
